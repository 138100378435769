/**
 * Sticky header to the top of the page when scrolling down.
 * Only on smaller screen sizes
 */

( function( $, window, document, undefined ) {

	let $header = $('.js-site-header');
	let height = $header.outerHeight();

	const BREAKPOINT_STICKY_HEADER = '(max-width: 900px)';

	function offset_content() {
		if ( window.matchMedia(BREAKPOINT_STICKY_HEADER).matches ) {
			$('main').css('margin-top', height);
		}
	}

	let $position_sticky_elements = $('.tabular-list__header .tabular-list__row > *');
	function update_sticky_position() {
		if ( window.matchMedia(BREAKPOINT_STICKY_HEADER).matches ) {
			$position_sticky_elements.css('top', $header.outerHeight());
		}
	}

	offset_content();
	update_sticky_position();

	$(window).on('resize', debounce( () => {
		// Get new offset
		height = $header.outerHeight();

		offset_content();
		update_sticky_position();
	}, 150) );
	
} )( jQuery, window, document );

/**
 * Fix focus on skip links + other anchor links.
 * @see https://axesslab.com/skip-links/#update-3-a-comment-from-gov-uk
 * @see https://github.com/selfthinker/dokuwiki_template_writr/blob/master/js/skip-link-focus-fix.js
 */

(function($) {

  const FOCUSABLE_ELEMENT_SELECTORS = [
    'a[href]',
    'area[href]',
    'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
    'select:not([disabled]):not([aria-hidden])',
    'textarea:not([disabled]):not([aria-hidden])',
    'button:not([disabled]):not([aria-hidden])',
    'iframe',
    'object',
    'embed',
    '[contenteditable]',
    '[tabindex]:not([tabindex^="-"])'
  ];

  jQuery.extend(jQuery.expr[':'], {
    focusable: function(el, index, selector){
      var $element = $(el);
      return $element.is( FOCUSABLE_ELEMENT_SELECTORS.join() ) && !$element.is(':hidden');
    }
  });

  function focusOnElement($element) {
    if (!$element.length) {
      return;
    }
    if (!$element.is(':focusable')) {
      // add tabindex to make focusable and remove again
      $element.attr('tabindex', -1).on('blur focusout', function () {
        $(this).removeAttr('tabindex');
      });
    }
    $element.focus();
  }


  // Cache all focusable elements on page
  let $focusables = $(':focusable');
  function getNextTabbable( $el ) {
    let index = $focusables.index($el);
    return $focusables.eq(index + 1).length ? $focusables.eq(index + 1) : $focusables.eq(0);
  }

  function getPrevTabbable( $el ) {
    let index = $focusables.index($el);
    return $focusables.eq(index - 1).length ? $focusables.eq(index - 1) : $focusables.eq($focusables.length - 1);
  }


  $(document).ready(function(){
    // if there is a '#' in the URL (someone linking directly to a page with an anchor)
    // if (document.location.hash) {
    //   focusOnElement($(document.location.hash));
    // }

    // if the hash has been changed (activation of an in-page link)
    $(window).bind('hashchange', function() {
      var hash = "#"+window.location.hash.replace(/^#/,'');
      focusOnElement($(hash));
    });
  });


  // Expose API
  window.FOCUSABLE_ELEMENT_SELECTORS = FOCUSABLE_ELEMENT_SELECTORS.join();
  window.focusOnElement = focusOnElement;
  window.getNextTabbable = getNextTabbable;
  window.getPrevTabbable = getPrevTabbable;

})(jQuery);

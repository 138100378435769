/**
 * Init search a11y-dialog library.
 */

( function( $, window, document, undefined ) {

	let $dialog = $('.js-search-dialog');
	let $dialog_loader = $dialog.find('.dialog__loader');
	let $dialog_content = $dialog.find('#ajax-search-results');

	let $search_input = $dialog.find('input');
	let previous_search = $search_input.get(0).value;

	let dialog = null;
	// object with abort method to avoid errors on first run
	let jqxhr = {abort: function () {}};

	/**
	 * Force a repaint of a specific DOM Node
	 * @param  {DOMElement} node A native DOM element to repaint.
	 *                           Not jQuery, use $element.get(0) to convert.
	 */
	let repaint = ( node ) => {
	 node.style.display = 'none';
	 node.offsetHeight; // jshint ignore:line
	 node.style.display = '';
	};

	function init() {
		dialog = new window.A11yDialog($dialog.get(0), '#site-header, #main, #site-footer');
		$dialog.addClass('a11y-dialog-initialized');
		$dialog.removeAttr('hidden');

		dialog.on('beforeshow', function (dialogEl, event) {
			/**
			 * 1)
			 * On iOS, when focus moves to the input, the keyboard will 
			 * open and scroll the screen leaving the input off screen.
			 * This fixes that by pushing the input up so that
			 * iOS detects it will not be covered by the keyboard
			 */
			$search_input.css('transform', 'translateY(-1000px)');

			$(document).trigger('pause_sticky_nav');
			bodyScrollLock.disableBodyScroll(document.querySelector('#search-dialog .dialog__content'), {reserveScrollBarGap: true});
	    	
	    	event.preventDefault();
	    });

		dialog.on('show', function (dialogEl, event) {
			// Reset updated position from 1) above
			$search_input.css('transform', '');
	    	
	    	event.preventDefault();
	    });

	    dialog.on('hide', function (dialogEl, event) {
			$(document).trigger('resume_sticky_nav');
			bodyScrollLock.enableBodyScroll(document.querySelector('#search-dialog .dialog__content'));

			remove_content();
			$search_input.val('');
		});

		$dialog.find('form').on('submit', function(event) {
			event.preventDefault();
			return false;
		});

		$search_input.on('keyup', debounce(function(event) {
			let term = this.value.trim();
			let paged = 1;

			if ( term === '' ) {
				remove_content();
				previous_search = '';
			}

			if ( !term || term === previous_search ) {
				// Don't handle events which don't change the search
				return;
			}

			previous_search = term;

			load_search_content(`${wp.home_url}search/${term}/page/${paged}/`);
		}, 800 ));
	}

	function change_state( state ) {
		if ( 'loading' == state ) {
			$dialog_content.attr('aria-busy', 'true');
			$dialog_loader.removeClass('fade-out');
		} else {
			$dialog_content.attr('aria-busy', 'false');
			$dialog_loader.addClass('fade-out');
		}
	}

    function load_search_content( url ) {
		let page_html = get_url_contents(url);

    	remove_content();
    	change_state('loading');

    	page_html.then( function(html) {
			insert_content($(html).find('#search-results').html());
		} );
    }

    function get_url_contents( url ) {
		let deferred = jQuery.Deferred();

		jqxhr.abort();

    	jqxhr = jQuery.ajax( {
			url: url,
			dataType: 'html',
			method: 'GET',
			success: function (data, textStatus, jqXHR) {
				deferred.resolve(data);
			},
			error: function(jqXHR, textStatus, errorThrown) {
				console.log( 'ERROR: get_search_page', errorThrown );
				deferred.reject();
			}
		} );

		return deferred;
    }

	function insert_content( html ) {
		change_state('done');
		
		$dialog_content.html(html);
		repaint($dialog_content.get(0)); // Fix IE 11 not showing content immediately

		$dialog_content.on('click.search', 'a.page-numbers, .search-dym a', function(event) {
			if ( !$(this).hasClass('page-numbers') ) {
				// is did you mean link - update searched term
				let term_regex = /search\/([a-z0-9+_-]+)$/;
				let macthes = term_regex.exec(this.href);
				let new_term = macthes[1];

				previous_search = new_term;
				$search_input.val(new_term);
			}

			load_search_content(this.href);
			
			event.preventDefault();
			return false;
		});
	}

	function remove_content() {
		change_state('done');

		$dialog_content.html('');
		$dialog_content.off('.search');
	}


	init();

    /**
     * If native dialog element is supported, we must handle backdrop clicks to close
     * @see https://keithjgrant.com/posts/2018/01/meet-the-new-dialog-element/#more-control
     */
	if ( dialog.useDialog ) {
		let native_dialog = $dialog.find('dialog').get(0);
		native_dialog.addEventListener('click', function(event) {
			if ( event.target === native_dialog ) {
				dialog.hide();
			}
		});
	}
	
} )( jQuery, window, document );

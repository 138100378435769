/**
 * Make an AJAX request to WordPress to get content
 * based on data parameters.
 */
function get_template_part( data ) {
	let deferred = jQuery.Deferred();

	if ( !data.action ) {
		data.action = 'get_template_part';
	}

	jQuery.ajax( {
		url: wp.ajax_url,
		data: data,
		dataType: 'html',
		method: 'GET',
		success: function (data, textStatus, jqXHR) {
			deferred.resolve(data);
		},
		error: function(jqXHR, textStatus, errorThrown) {
			console.log( 'ERROR: get_template_part', errorThrown );
			deferred.reject();
		}
	} );

	return deferred;
}

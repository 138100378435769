/**
 * Animate an image wall to transition through
 * all available images.
 */

( function( $, window, document, undefined ) {
	/**
	 * Shuffles array in place.
	 * @param {Array} a items An array containing the items.
	 */
	function shuffle(a) {
	    var j, x, i;
	    for (i = a.length - 1; i > 0; i--) {
	        j = Math.floor(Math.random() * (i + 1));
	        x = a[i];
	        a[i] = a[j];
	        a[j] = x;
	    }
	    return a;
	}

	class Image_Wall {
		constructor( $container, options ) {
			let _ = this;

			/**
			 * Container
			 * @type {jQuery}
			 */
			_.$c = $($container);
			/**
			 * Image List.
			 * An array of HTML img tags.
			 * @type {Array}
			 */
			_.l = _.$c.data('imageWall');
			/**
			 * Child items.
			 * @type {jQuery}
			 */
			_.$i = _.$c.children();

			/**
			 * Current pause or play state.
			 * @type {Boolean}
			 */
			_.is_playing = true;

			/**
			 * Holds the ID for the next scheduled transition
			 * while playing.
			 * @type {Number}
			 */
			_.nextTickId = null;

			_._indexes = [];
			for (var i = 0, len = _.$i.length; i < len; i++) {
				_._indexes.push(i);
			}
			_._indexes2 = _._indexes.slice(0);


			_.options = $.extend( {}, {
				/**
				 * The speed of the image transition.
				 * @type {Number}
				 */
				duration: 500,
				/**
				 * How long to wait between each transition.
				 * @type {Number}
				 */
				delay: 1500,
				/**
				 * Whether to autoplay by default.
				 * @type {Boolean}
				 */
				autoplay: true, 
				/**
				 * Whether to loop when hitting the end of the image list.
				 * @type {Boolean}
				 */
				loop: true
			}, options );

			_._bindEvents();

			if ( _.options.autoplay ) {
				_.is_playing = true;
				_.nextTickId = setTimeout(function() {
					_.play();
				}, _.options.delay);
			}
		}
	
		/**
		 * Bind event listeners
		 */
		_bindEvents() {
			let _ = this;

		}

		/**
		 * Start the transition animation.
		 */
		play() {
			let _ = this;

			_.is_playing = true;
			_._before_transition();

			return _;
		}

		/**
		 * Stop the transition animation.
		 */
		pause() {
			let _ = this;

			_.is_playing = false;
			if ( _.nextTickId ) {
				clearTimeout(_.nextTickId);
				_.nextTickId = null;
			}

			return _;
		}

		/**
		 * Setup for the upcoming transition.
		 */
		_before_transition() {
			let _ = this;

			_.current_index = _._pick_index();

			let $chosen = _.$i.eq(_.current_index);
			let $prev = $chosen.find('img').eq(0);
			let next_html = _.l.shift();
			let $next = $(next_html);

			// Place next back into image list
			if (_.options.loop) {
				_.l.push(next_html);
			}

			$next.addClass('overlap is-next');
			$chosen.append($next);

			$chosen.imagesLoaded( function() {
				_._transition( $prev, $next );
			} );

			return _;
		}

		/**
		 * Pick a random index to transition to.
		 * It will touch each index of the wall 
		 * at least once before repeating.
		 * @return {Number} 
		 */
		_pick_index() {
			let _ = this;

			shuffle(_._indexes);

			let next_index = _._indexes.shift();
			if ( next_index === _.current_index ) {
				// Call recursively if same index is picked twice in a row
				// Should only ever recurse 1 level deep
				next_index = _._pick_index();
			}

			if ( 0 === _._indexes.length ) {
				_._indexes = _._indexes2.slice(0);
			}

			return next_index;
		}

		/**
		 * Transition from one image to the next
		 * @param  {jQuery} $prev Previous image element.
		 * @param  {jQuery} $next Next image element.
		 */
		_transition( $prev, $next ) {
			let _ = this;

			$prev.css('opacity', 0);
			$next.css('opacity', 1);

			setTimeout(function() {
				_._after_transition();
			}, _.options.duration);

			return _;
		}

		/**
		 * Clean up after a transition finishes and 
		 * queue up the next one.
		 */
		_after_transition() {
			let _ = this;

			let $chosen = _.$i.eq(_.current_index);
			let $images = $chosen.find('img');

			$images.removeClass('overlap is-next');
			$images.eq(0).remove();

			// Check flag
			if ( _.is_playing && _.l.length ) {
				_.nextTickId = setTimeout(function() {
					_.play();
				}, _.options.delay);
			}

			return _;
		}
	}


	let $image_wall = $('[data-image-wall]');

	if ( $image_wall.length ) {
		$image_wall.each( function(index, image_wall) {
			image_wall.Image_Wall = new Image_Wall($(image_wall), {
				duration: 500,
				delay: 750
			});
		} );
	}
	
} )( jQuery, window, document );

/**
 * Open and close submenus in a11y friendly way.
 *
 * Should be able to `tab` across all top level menu items.
 * 		Does not open the submenu when a top level item is focused.
 *
 * Pressing `enter` should expand the submenu *but* not move focus into it.
 * 1)	Submenu state is controlled by aria-expanded attr on 
 *   	top level item ensuring a11y attrs must be set to use it.
 *   	Focus does not move.
 *
 * Pressing `tab` once open should move to first submenu item.
 * 		We get this "for free" due to our submenu links 
 * 		directly following the top level link in the DOM structure.
 *
 * If `Esc` is pressed while in the submenu, it should collapse and
 * focus returns to the top level item.
 * 		See 2)
 *
 * If `tab` is pressed on the last submenu item, submenu should close 
 * and focus transferred to the next top level item.
 * 		3) Checks for focus on the next tabbable element to detect this.
 *
 * If `Shift` + `tab` is pressed on the first submenu item, 
 * focus moves naturally to the top level item.
 * If pressed again, submenu should close and focus moves 
 * to previous top level item/previous tabbable element
 * 		4) Checks for focus on the previous tabbable element to detect this.
 */

( function( $, window, document, undefined ) {
	
	const IS_MOBILE_NAV_MQ = '(max-width: 900px)';
	let $triggers = $('.js-open-submenu');


	function open( $trigger ) {
		// 1)
		$trigger.attr('aria-expanded', 'true');
		$trigger.next().attr('aria-hidden', 'false');
	}

	function close( $trigger ) {
		// 1)
		$trigger.attr('aria-expanded', 'false');
		$trigger.next().attr('aria-hidden', 'true');
	}


	/**
	 * KEYBOARD SUPPORT
	 */

	function bind_keyboard_close_handlers( $trigger ) {
		let $prev_tab = getPrevTabbable($trigger);
		let $next_tab = getNextTabbable($trigger);

		// 2)
		function esc_handler(event) {
			if ( 'Escape' !== event.key ) {
				return;
			}

			// Move focus back to trigger if inside the submenu
			if ( $trigger.next().is( $(document.activeElement).closest('.submenu') ) ) {
				focusOnElement($trigger);
			}

			close($trigger);
			unbind_keyboard_handlers();
		}

		function blur_handler(event) {
			close($trigger);
			unbind_keyboard_handlers();
		}

		function unbind_keyboard_handlers() {
			$(document).unbind('keydown', esc_handler);
			$prev_tab.unbind('focus', blur_handler);
			$next_tab.unbind('focus', blur_handler);
		}

		$(document).bind('keydown', esc_handler);
		// Menu has been blurred if either of these get focus
		$prev_tab.bind('focus', blur_handler); // 4)
		$next_tab.bind('focus', blur_handler); // 3)
	}


	function close_expanded_trigger() {
		let $expanded_trigger = $triggers.filter('[aria-expanded="true"]');
		if ( $expanded_trigger.length ) {
			close($expanded_trigger);
		}
	}

	$triggers.on( 'keydown', function(event) {
		if ( window.matchMedia(IS_MOBILE_NAV_MQ).matches ) {
			return;
		}

		// 1)
		if ( 'Enter' !== event.key ) {
			return;
		}

		let $trigger = $(event.target);

		// Block default and open submenu only once
		// Further Enter presses will navigate to link
		if ( 'false' === $trigger.attr('aria-expanded') ) {
			bind_keyboard_close_handlers($trigger);
			open($trigger);

			event.preventDefault();
			return false;
		}
	} );


	/**
	 * TOUCH SUPPORT
	 *
	 * 5) Tapping on an item will open the submenu associated with it.
	 * 5.1) Tapping on the same item again will naigate to it.
	 * 6) Tapping on any other item will close the active submenu.
	 * 7) Tapping outside of the submenu will close it.
	 * 8) Zoom, or other multi-touch gestures should not open or close the menu.
	 */

	let touch_handler_bound = false;

	function touch_handler(event) {
		// 8)
		if ( event.touches.length > 1 ) {
			return;
		}

		let $closest_submenu = $(event.target).closest('.submenu');
		if ( $closest_submenu.length ) {
			return;
		}

		// 7)
		close_expanded_trigger();
		unbind_touch_close_handler();
	}

	function bind_touch_close_handler() {
		$(document).bind('touchend', touch_handler);
		touch_handler_bound = true;
	}

	function unbind_touch_close_handler() {
		$(document).unbind('touchend', touch_handler);
		touch_handler_bound = false;
	}

	$triggers.on( 'touchend', function(event) {
		if ( window.matchMedia(IS_MOBILE_NAV_MQ).matches ) {
			return;
		}
		
		// 8)
		if ( event.touches.length > 1 ) {
			return;
		}

		let $trigger = $(event.target);
		if ( !$trigger.is('.js-open-submenu') ) {
			$trigger = $trigger.closest('.js-open-submenu');
		}

		// Block default and open submenu only once
		// 5.1)
		if ( 'false' === $trigger.attr('aria-expanded') ) {
			if ( !touch_handler_bound ) {
				bind_touch_close_handler();
			}

			// 6)
			close_expanded_trigger();
			// 5)
			open($trigger);

			event.preventDefault();
			return false;
		}
	} );

} )( jQuery, window, document );

(function() {
  // Get all the heading triggers
  const headings = document.querySelectorAll('.accordion-trigger');
  
  Array.prototype.forEach.call(headings, (heading, index) => {
    // Give each trigger a toggle button child
    heading.innerHTML = `
      <button type="button" aria-expanded="false">
        ${heading.textContent}
      </button>
    `;
    
    // Function to create a node list 
    // of the content between this trigger and the next
    const get_content = (elem) => {
      let elems = [];

      while (elem.nextElementSibling && elem.nextElementSibling.tagName !== 'H3') {
        elems.push(elem.nextElementSibling);
        elem = elem.nextElementSibling;
      }

      return elems;
    };
    
    // Assign the contents to be expanded/collapsed (array)
    let contents = get_content(heading);
    
    // Create a wrapper element for `contents` and hide it
    let wrapper = document.createElement('div');
    wrapper.hidden = true;
    
    contents.forEach(node => {
      // Move each element of `contents` to `wrapper`
      wrapper.appendChild(node);
    });
    
    // Add the wrapped content back into the DOM 
    // after the heading
    heading.parentNode.insertBefore(wrapper, heading.nextElementSibling);
    
    // Assign the button
    let btn = heading.querySelector('button');
    
    btn.onclick = () => {
      // Cast the state as a boolean
      let expanded = btn.getAttribute('aria-expanded') === 'true' || false;
      
      // Switch the state
      btn.setAttribute('aria-expanded', !expanded);
      // Switch the content's visibility
      wrapper.hidden = expanded;
    };

    // Check data attribute if first accordion should be open by default
    if ( 0 === index ) {
      let show_first = heading.parentNode.getAttribute('data-show-first') === 'true' || false;

      if ( show_first ) {
        btn.setAttribute('aria-expanded', 'true');
        wrapper.hidden = false;
      }
    }
  });
})();

/**
 * Init a11y-dialog library.
 */

( function( $, window, document, undefined ) {

	let $dialog = $('.js-dialog');
	let $dialog_loader = $dialog.find('.dialog__loader');
	let $dialog_content = $dialog.find('#dialog-ajax-wrapper');

	let dialog = null;

	/**
	 * Force a repaint of a specific DOM Node
	 * @param  {DOMElement} node A native DOM element to repaint.
	 *                           Not jQuery, use $element.get(0) to convert.
	 */
	let repaint = ( node ) => {
	 node.style.display = 'none';
	 node.offsetHeight; // jshint ignore:line
	 node.style.display = '';
	};

	function init() {
		dialog = new window.A11yDialog($dialog.get(0), '#site-header, #main, #site-footer');
		$dialog.addClass('a11y-dialog-initialized');
		$dialog.removeAttr('hidden');

		dialog.on('show', function (dialogEl, event) {
			$(document).trigger('pause_sticky_nav');
			bodyScrollLock.disableBodyScroll(document.querySelector('#dialog .dialog__content'), {reserveScrollBarGap: true});

			setTimeout(function() {
				dialogEl.classList.add('_block-animation');
			}, 300);
	    	
	    	event.preventDefault();
	    });

	    dialog.on('hide', function (dialogEl, event) {
			dialogEl.classList.remove('_block-animation');

			$(document).trigger('resume_sticky_nav');
			bodyScrollLock.enableBodyScroll(document.querySelector('#dialog .dialog__content'));

			remove_content();
		});

		// Link up dynamic openers
		$(document).on('click', '[data-dialog]', function(event) {
			let $dialog_content = $dialog.find('#dialog-ajax-wrapper');

			change_state('loading');
			load_dialog_content(get_dialog_data(this));

			dialog.show(event);

			event.preventDefault();
			return false;
		});
	}

	function change_state( state ) {
		if ( 'loading' == state ) {
			$dialog_content.attr('aria-busy', 'true');
			$dialog_loader.removeClass('fade-out');
		} else {
			$dialog_content.attr('aria-busy', 'false');
			$dialog_loader.addClass('fade-out');
		}
	}

	function load_dialog_content( data ) {
		let request = get_template_part(data);

		request.then( function(html) {
			insert_content(html, data);

			if ( data.scroll && 'bottom' == data.scroll ) {
				$dialog_content.imagesLoaded( function() {
					$dialog_content.get(0).scrollTop = 9999; // Use large number to ensure forced to bottom
				} );
			}

			if ( data.qa && $dialog.find(data.qa).length ) {
				$dialog.find(data.qa).get(0).hidden = false;
			}
		} );
	}

	function get_dialog_data( el, attr = 'dialog' ) {
		let data = $(el).data(attr);
		let ret = {};

		data = data.split('&');
		data.forEach( (i) => {
			i = i.split('=');
			ret[ i[0] ] = i[1];
		} );

		return ret;
	}

	function insert_content( html, data = null ) {
		change_state('done');

		$dialog_content.html(html);
		repaint($dialog_content.get(0)); // Fix IE 11 not showing content immediately

		if ( $dialog_content.find('.js-google-map').length ) {
			if ( $dialog_content.height() > 0 ) {
				$dialog_content.find('.js-google-map').css('--map-height', $dialog_content.height() - $dialog_content.find('.dialog-content header').outerHeight());
			}

			create_map($dialog_content.find('.js-google-map').get(0));
		}

		if ( !data ) {
			return true;
		}

		$dialog.on('click.quick_action', 'button.dialog__quick-action', function(event) {
			let toggle_to = get_dialog_data(this, 'toggle');

			toggle_to.post_id = data.post_id;
			load_dialog_content(toggle_to);

			remove_content();
			change_state('loading');
		});

		return true;
	}

	function remove_content( $dialogEl ) {
		change_state('done');

		$dialog_content.html('');
		$dialog.find('.dialog__quick-action').attr('hidden', 'true');

		$dialog.off('click.quick_action');
	}


	init();

    /**
     * If native dialog element is supported, we must handle backdrop clicks to close
     * @see https://keithjgrant.com/posts/2018/01/meet-the-new-dialog-element/#more-control
     */
	if ( dialog.useDialog ) {
		let native_dialog = $dialog.find('dialog').get(0);
		native_dialog.addEventListener('click', function(event) {
			if ( event.target === native_dialog ) {
				dialog.hide();
			}
		});
	}
	
} )( jQuery, window, document );

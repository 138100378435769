( function( $, window, document, undefined ) {

	let $toggle = $('.js-site-nav-toggle');
	let $body = $('body');
	let body_class = '-nav-is-open';

	const MOBILE_NAV_BREAKPOINT = '(min-width: 901px)';

	// Add default attr
	$toggle.attr('aria-expanded', 'false');

	function open() {
		$toggle.attr('aria-expanded', 'true');
		$toggle.addClass('is-active');

		$body.addClass(body_class);
		bodyScrollLock.disableBodyScroll(document.querySelector('.js-site-nav'), {reserveScrollBarGap: true});

		$(document).on('keydown.mobile_nav', (event) => {
			if ( 'Escape' !== event.key ) {
				return;
			}

			close();

			event.preventDefault();
			return false;
		});

		$(document).on('click.mobile_nav touchend.mobile_nav', (event) => {
			if ( !$(event.target).closest('.site-nav').length ) {
				// click/touch was outside menu, assume in shade space and close menu
				close();

				event.preventDefault();
				return false;
			}
		});
	}

	function close() {
		$toggle.attr('aria-expanded', 'false');
		$toggle.removeClass('is-active');

		$body.removeClass(body_class);
		bodyScrollLock.enableBodyScroll(document.querySelector('.js-site-nav'));

		$(document).off('.mobile_nav');
	}

	function is_open() {
		return $toggle.is('[aria-expanded="true"]');
	}

	function toggle() {
		if ( is_open() ) {
			close();
		} else {
			open();
		}
	}


	$toggle.on('click', (event) => {
		toggle();

		event.preventDefault();
		return false;
	});	

	$(window).on('resize', throttle( () => {
		if ( is_open() && window.matchMedia(MOBILE_NAV_BREAKPOINT).matches ) {
			close();
		}
	}, 150 ));
	
} )( jQuery, window, document );

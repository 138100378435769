// Contact Form Handler

( function( $, window, document, undefined ) {

	const $contact_form = $('#contact_form');
	const $submit_button = $contact_form.find('#contact_form_submit');

	/**
	 * Validate the form fields.
	 * @uses FormField helper to wrap the fields and validate common types.
	 * @see  _form-helpers.js
	 * @return {Boolean}
	 */
	function validate_form() {
		let fullname = new FormField( $contact_form.find('#fullname') );
		let email = new FormField( $contact_form.find('#email') );
		let phone = new FormField( $contact_form.find('#phone') );

		let address = new FormField( $contact_form.find('#address') );
		let city = new FormField( $contact_form.find('#city') );
		let postcode = new FormField( $contact_form.find('#postcode') );

		let message = new FormField( $contact_form.find('#message') );

		let validated = true;

		if ( !fullname.is_required_and_valid() ) {
			fullname.add_error( 'Enter your name' );
			validated = false;
		} else if( !fullname.is_empty() && !fullname.is_min_length() ) {
			fullname.add_error( `Enter at least ${ fullname.$field.attr('minlength') } characters` );
			validated = false;
		}

		if ( !email.is_required_and_valid() ) {
			email.add_error( 'Enter your email' );
			validated = false;
		} else if ( !email.is_empty() && !email.is_email() ) {
			email.add_error( 'Enter a valid email' );
			validated = false;
		}

		if ( !phone.is_required_and_valid() ) {
			phone.add_error( 'Enter your phone number' );
			validated = false;
		} else if ( !phone.is_empty() && !phone.is_phone() ) {
			phone.add_error( 'Enter a valid phone number' );
			validated = false;
		}


		// If started entering an address, check all other fields are filled
		if ( 
			!address.is_empty() ||
			!city.is_empty() ||
			!postcode.is_empty()
		) {
			if ( address.is_empty() ) {
				address.add_error( 'Enter your address' );
				validated = false;
			}

			if ( city.is_empty() ) {
				city.add_error( 'Enter your city' );
				validated = false;
			}

			if ( postcode.is_empty() ) {
				postcode.add_error( 'Enter your postcode' );
				validated = false;
			}
		}


		if ( !message.is_required_and_valid() ) {
			message.add_error( 'Enter a message' );
			validated = false;
		}


		return validated;
	}
	
	

	if ( $contact_form.length ) {

		// Stop the browser showing it's default error handling UI, only when the JS has loaded
		$contact_form.attr( 'novalidate', 'true' );

		// Listen for bubbled events from button
		// Submit events fire default HTML5 validation as well
		$submit_button.on( 'click', (event) => {

			if ( !validate_form() ) {
				// Scroll to and focus first error
				let error_found = false;
				$.each( $contact_form.find('input,textarea,select'), ( index, field ) => {
					let $field = $(field);

					if ( 'true' === $field.attr('data-error') && !error_found ) {
						$('html, body').animate({
							scrollTop: $field.offset().top - 120
						}, 400 );

						$field.focus();

						error_found = true;
					}
				} );

				event.preventDefault();
				return false;
			}

		} );

		$contact_form.on( 'submit', (event) => {
			$submit_button.html( 'Sending&hellip;' );
		} );

	}

	// Listen for change events and hide field errors
	$contact_form.find('input, textarea, select').on( 'change', (event) => {
		let $this = $(event.target);

		$this.trigger('remove_error');
	} );

} )( jQuery, window, document );

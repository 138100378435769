/**
 * Sticky nav to the top of the page when scrolling down.
 */

( function( $, window, document, undefined ) {

	const BREAKPOINT_STICKY_NAV = '(max-width: 900px)';
	const BREAKPOINT_STICKY_NAV_ABOVE = '(min-width: 901px)';

	// Allow other scripts to disable this scroll check
	let is_paused = false;

	let $nav = $('.js-site-nav');
	let nav_offset = $nav.offset().top;
	// Scrolled flag ensures style only changes when threshold crossed
	let scrolled_flag = false;

	function sticky_nav( force = false ) {
		if ( (is_paused && !force) || window.matchMedia(BREAKPOINT_STICKY_NAV).matches ) {
			return;
		}

		let scroll_top = $(window).scrollTop();

		if ( !scrolled_flag && scroll_top >= nav_offset ) {
			$('main').css('margin-top', $nav.outerHeight());
			$nav.addClass('-is-fixed');

			scrolled_flag = true;
		} else if ( scrolled_flag && scroll_top < nav_offset ) {
			$('main').css('margin-top', '');
			$nav.removeClass('-is-fixed');

			scrolled_flag = false;
		}
	}

	sticky_nav();
	$(window).on( 'scroll', sticky_nav );

	let $position_sticky_elements = $('.tabular-list__header .tabular-list__row > *');
	function update_sticky_position() {
		if ( window.matchMedia(BREAKPOINT_STICKY_NAV_ABOVE).matches ) {
			$position_sticky_elements.css('top', $nav.outerHeight());
		}
	}

	update_sticky_position();

	$(window).on('resize', debounce( () => {
		if ( window.matchMedia(BREAKPOINT_STICKY_NAV).matches ) {
			return;
		}

		// Remove current fixed if added
		$('main').css('margin-top', '');
		$nav.removeClass('-is-fixed');
		scrolled_flag = false;

		// Get new offset
		nav_offset = $nav.offset().top;

		// Update nav state
		sticky_nav(true);
		update_sticky_position();
	}, 150) );


	/**
	 * Pause can be triggered with:
	 * $(document).trigger('pause_sticky_nav');
	 * Resume with:
	 * $(document).trigger('resume_sticky_nav');
	 */
	$(document).on('pause_sticky_nav', () => {
		is_paused = true;
	});
	$(document).on('resume_sticky_nav', () => {
		is_paused = false;
	});
	
} )( jQuery, window, document );

// Fix object-fit display in non-supporting browsers
( function() {
	
	/**
   * Given an img node, get the largest source from it's src and srcset attributes.
   * The largest image will be determined on width alone. 
   * Assumes that the img width attribute is set and correct.
   * @param  {Node}   img An image node.
   * @return {String} The URL of the largest image.
   */
  function getLargestImageSrc( img ) {
    if ( img.tagName.toLowerCase() !== 'img' ) {
      return '';
    }

    let widths = [];

    widths.push( { 
      url: img.src,
      width: parseInt( img.getAttribute('width') ) 
    } );

    if ( img.getAttribute('srcset') ) {
      img.getAttribute('srcset').split(', ').forEach( (src) => {
        let srcParts = src.split(' ');
        let width = parseInt( srcParts[1].slice( 0, -1 ) );

        if ( !isNaN(width) ) {
          widths.push( {
            url: srcParts[0],
            width: width
          } );
        }
      } );
    }

    // Reduce to the largest width found in the array, 
    // then get the url key of that object
    return widths.reduce( (accumulator, current, index, array) => {
      return accumulator.width > current.width ? accumulator : current;
    }, { url: '', width: 0 } ).url;
  }
	
  /**
   * Ponyfill object-fit by wrapping image and placing as background on wrapper.
   * @param  {jQuery} $image An image node.
   * @return {jQuery} The new wrapping node,
   *                  or null if not an image with object fit is passed.
   */
	function ponyfillObjectFit( $image ) {
		let	objectFit = $image.data('objectFit');

    if ( $image.hasClass('link-icon') ) {
      objectFit = 'contain';
    }

		// Only process image nodes
    if ( 
    	$image.prop('tagName') === 'IMG' && 
    	( 'cover' == objectFit || 'contain' == objectFit ) 
    ) {

			$image.wrap( `<div></div>` );
			const $wrapper = $image.parent();

			// Move class from image to new wrapping element
			$wrapper.attr( 'class', $image.attr('class') );
			$image.removeAttr('class');

			$wrapper.css( {
				'background-image':  `url(${ getLargestImageSrc( $image.get(0) ) })`,
				'background-size': objectFit,
				'background-repeat': 'no-repeat',
				'background-position': 'center'
			} );

			$image.css( {
				visibility: 'hidden'
			} );

			return $wrapper;
		}

		return null;
	}

	if ( !Modernizr.objectfit ) {
		const $objectFitElements = jQuery('[data-object-fit], .link-icon');

		if ( $objectFitElements.length ) {
			$objectFitElements.each( ( index, element ) => {
				ponyfillObjectFit( jQuery(element) );
			} );
		}
	} // / !Modernizr.objectfit

} )();

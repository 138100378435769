function load_script( src ) {
	var body = document.getElementsByTagName('BODY')[0];  
    var script = document.createElement('script'); 
    
    if ( wp.manifest ) {
        src = wp.manifest[src];
    }
    
	src = wp.stylesheet_directory_uri + 'dist/' + src;

    script.src = src;
    body.appendChild(script); 
}

function load_style( src ) {
	var head = document.getElementsByTagName('HEAD')[0];  
    var link = document.createElement('link'); 
    
    if ( wp.manifest ) {
        src = wp.manifest[src];
    }

	src = wp.stylesheet_directory_uri + 'dist/' + src;

    link.rel = 'stylesheet';  
    link.type = 'text/css'; 
    link.href = src;
    head.appendChild(link); 
}


// Conditionally load datepickers
var is_date_supported = ( function () {
	var input = document.createElement('input');
	var value = 'a';
	input.setAttribute('type', 'date');
	input.setAttribute('value', value);
	return (input.value !== value);
} )();

var $date_inputs = document.querySelectorAll('input[type="date"]');

if ( !is_date_supported && $date_inputs.length ) {
	load_style('css/bootstrap-datepicker.min.css');
	load_script('js/datepicker.min.js');
}


// Conditionally load slick carousel
var $carousels = document.querySelectorAll('.js-split-carousel');
var $hero_carousel = document.querySelectorAll('.js-hero-carousel');

if ( $carousels.length || $hero_carousel.length ) {
	load_style('css/slick.min.css');
	load_script('js/slick.min.js');
}


// Conditionally load select2 plugin
var $select2_inputs = document.querySelectorAll('.js-county-select, .js-lpa-select');

if ( $select2_inputs.length ) {
    load_style('css/select2.min.css');
    load_script('js/select2.min.js');
}

// Init Odometer.js

( function( $, window, document, undefined ) {

	let $odometers = $('[data-odometer]');

	if ( !$odometers.length ) {
		return;
	}
	
	/**
	 * Check if an element has been scrolled past, 
	 * i.e. it is on screen or above the viewport.
	 * @param  {jQuery}  $element The element to check.
	 * @return {Boolean}          
	 */
	function hasBeenScrolledPast( $element ) {
	  var elementTop = $element.offset().top,
	      screenTop = jQuery(window).scrollTop(),
	      screenBottom = screenTop + jQuery(window).outerHeight();

	  return screenBottom >= elementTop;
	}

	// Maintain a global state of odometers to check on scroll
	let odometers = [];
	let keywords = {
		currentYear: new Date().getFullYear()
	};

	$odometers.each( (index, odometer) => {
		const $odometer = $(odometer);
		const $accordion_wrapper = $odometer.closest('.js-accordion-content');

		odometers[index] = false;

		const values = $odometer.data('odometer');
		const from = '_' === values[0].substr( 0, 1 ) ? keywords[ values[0].substr( 1 ) ] : values[0];
		const to = '_' === values[1].substr( 0, 1 ) ? keywords[ values[1].substr( 1 ) ] : values[1];

		function reset() {
			$odometer.html(from);
		}

		function count_up() {
			$odometer.html(to);
			odometers[index] = false;
		}

		let odometer_format = '(,ddd).d';
		if ( 'false' == odometer.getAttribute('data-odometer-formatting') ) {
			odometer_format = '(ddd).d';
		}

		let odometer_instance = new Odometer({
			el: odometer,
			// Fetch dynamic variable from keywords map if has leading underscore
			value: from,

			// Any option (other than auto and selector) can be passed in here
			format: odometer_format,
			theme: 'default'
		});

		odometer.innerHTML = from;

		// Add odometer and methods to scroll checks
		odometers[index] = {
			el: $odometer,
			reset,
			count_up
		};
	} );

	function check_odometers() {
		if ( !odometers || 0 === odometers.filter( value => value ).length ) {
			return;
		}

		odometers.forEach( (object) => {
			if ( object && hasBeenScrolledPast(object.el) ) {
				object.count_up();
			}
		} );
	}

	check_odometers();
	$(window).on( 'scroll', throttle( check_odometers, 150 ) );

} )( jQuery, window, document );
